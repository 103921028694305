import React from "react";
import styled from "styled-components";
// Components

// Assets
import onboarding from "../assets/img/onboarding2.png";
// Assets
import OtherNavbar from "../components/Nav/OtherNavbar";
import Courses from "../components/Sections/Courses";
import TargetUsers from "../components/Sections/TargetUsers";
import Benefits from "../components/Sections/Benefits";
import Contact from "../components/Sections/Footer";
import RegisterHere from "../components/Sections/RegisterHere";

export default function Onboarding() {
  return (
    <>
      <OtherNavbar />
      <br/><br/>
      <Wrapper id="home" className="container flexSpaceCenter">
          <ImageWrapper>
          {/* 'rgb(11 9 59)' */}
          <div style={{backgroundImage:`linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.7)),url(${onboarding})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat', padding:'30px', borderRadius:'16px'}}>
                <p className="whiteColor fontResize" >
                  <span className="orangeColor" style={{ fontWeight: "bold" }}>
                    About JM Tech Centre
                  </span>{" "}
                  <br /><br/>
                <p style={{color:'#fff'}}>
                The JM Tech Centre IT Skilling Program is anchored on the
                  Microsoft Future-Ready Program. It is a digital empowerment
                  program delivered in partnership with Microsoft to upskill
                  higher education students, build capacity in IT, and prepare
                  our tech champions for local and global employability after
                  school. This program offers everyone access to rich
                  technology content and mentors via an online platform. It is
                  organized into learning paths to give an end-to-end view of a
                  technology area and ensure that you build a comprehensive
                  skill set.
                </p>
                  {/* <em>Your future in tech starts here. Get ready to become a Global Solutions Provider</em> */}
                </p>
                <p
                  className="font13 orangeColor textRight"
                  style={{ marginTop: "10px" }}
                >
                </p>
              </div>
          </ImageWrapper>
      </Wrapper>
      <div>
        <TargetUsers />
        <Courses />
        <Benefits />
        <RegisterHere/>
        <Contact />
      </div>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
  min-height: 400px;
  @media (max-width: 960px) {
    flex-direction: column;
    padding-top: 100px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;

