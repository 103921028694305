import {useState,createContext,useContext} from "react";

const AuthContext = createContext(null)

export const AuthProvider = ({children}) => {
    const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")))
    // const user = JSON.parse(localStorage.getItem("user"));

    

    const login = user =>{
        if(user){
            setUser(user);
            localStorage.setItem("user", JSON.stringify(user));
        }
    }
    const logout = () =>{
        setUser(null)
          localStorage.removeItem("user");
    }

    return <AuthContext.Provider value={{user, login, logout}}>
           {children}
            </AuthContext.Provider>
}


export const useAuth = ()=>{
    return useContext(AuthContext)
} 