import React from "react";
import styled from "styled-components";
// Components

import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper>
      <div className="whiteBg">
        <div className="container">
        <div className="lightBg" style={{padding: '5px 0'}}>
        <div className="container" id="blog" >
          <HeaderInfo>
            <h1 className="font40 extraBold">The JM Tech Centre Statement</h1>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
        <br/><br/>
      </div>        
        </div>
      </div>
      
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 60px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;