import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";


export default function TargetUsers() {
  return (
    <>
      <Wrapper id="targetusers">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold" style={{textAlign:'left'}}>Who is this program for?</h1>
            <p className="font13"style={{textAlign:'left'}}>
             This program is designed and made available for a wide variety of users:
            </p>
          
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
              <BlogBox
                title=" Tertiary Institution (Public and Private)"
                text="Students of any discipline who have an interest in developing IT skills." 
              />
             
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
              <BlogBox
                title="Educators (Teachers & Lecturers)"
                text="Teachers & Lecturers who want to enhance their work with relevant skills in IT could also enroll in the program."
    
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
              <BlogBox
                title="National Youth Corps Members"
                text="Graduates who are currently serving and hope to start a career or a business with the help of technology."
                
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
              <BlogBox
                title="Unemployed Graduates"
                text="Unemployed Graduates of any discipline who want to upskill themselves for jobs."
                
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Secondary School (SS2 & SS3)"
                text="Students who have an interest/passion in developing IT skills could also enroll in the program and follow the track that will enhance their academics and future work."
                
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Corporate Workers"
                text="Employees who want to build a career or switch careers in Tech."
                
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Religious Institutions/Personnel"
                text="Religious Institutions who want to harness the advantage of Tech."
                
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
    <br/><br/>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;