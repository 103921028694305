import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Typography,
  createTheme,
  MuiThemeProvider,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Hidden,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import { ReactComponent as LoginImage } from "../assets/loginimage.svg";
import { ReactComponent as LoginBg } from "../assets/loginbg.svg";
import newlogo from "../assets/newlogo.png";
import { useAuth } from "../auth";
import { useHistory, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const getMuiTheme = createTheme({
  palette: {
    secondary: { main: "#1a1a1a" },
    primary: { main: "#fe0000" },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        fontSize: "x-large",
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    color: theme.palette.text.secondary,
    height: "100vh",
  },
  box: {
    height: "100vh",
    overflow: "hidden",

    [theme.breakpoints.only("xs")]: {
      overflow: "auto",
    },
  },
  adminbg: {
    background:
      "radial-gradient(80.75% 72.11% at -30.75% 27.89%, #FE0000 0%, rgba(254, 0, 0, 0.52) 23.67%, rgba(254, 0, 0, 0.55) 32.57%, rgba(254, 0, 0, 0.61) 39.08%, rgba(254, 0, 0, 0.67) 46.08%, rgba(254, 0, 0, 0.67) 52.41%, rgba(254, 0, 0, 0.63) 57.37%, rgba(254, 0, 0, 0.63) 64.99%, rgba(254, 0, 0, 0.62) 83.36%, rgba(254, 0, 0, 0.65) 100%)",
  },
  loginText: {
    color: "#FFF",
    fontWeight: "bold",
  },
  loginminitext: {
    color: "#1a1a1a",
    fontSize: "12px",
  },
  loginbtn: {
    background:
      "radial-gradient(80.75% 72.11% at -30.75% 27.89%, #FE0000 0%, rgba(254, 0, 0, 0.52) 23.67%, rgba(254, 0, 0, 0.55) 32.57%, rgba(254, 0, 0, 0.61) 39.08%, rgba(254, 0, 0, 0.67) 46.08%, rgba(254, 0, 0, 0.67) 52.41%, rgba(254, 0, 0, 0.63) 57.37%, rgba(254, 0, 0, 0.63) 64.99%, rgba(254, 0, 0, 0.62) 83.36%, rgba(254, 0, 0, 0.65) 100%)",
    padding: "10px",
    color: "#fff",
    textTransform: "capitalize",
  },
}));

const AdminLogin = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const initialFormValues = {
    token: null,
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [showPassword, setShowPassword] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [helperText, showHelperText] = useState(false);
  const [colors, setColors] = useState("secondary");
  var date = new Date();
  var hrs = date.getHours();
  const auth = useAuth();
  const handleChange = (e) => {
    const keyValue = e.target.value;
    e.preventDefault();
    setFormValues({ ...formValues, token: keyValue });
    showHelperText(false);
    setColors("secondary");
    // setAg(e.target.value);
  };
  const handleEnterKey =(e)=>{
    if (e.key === 'Enter') {
      handleLogin();
    }
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = () => {
    setBtnLoader(true);
    if (formValues.token === "" || formValues.token === null) {
      setBtnLoader(false)
      toast.warning("Please provide a token");
    } else {
      if (formValues.token === process.env.REACT_APP_ADMIN_LOGIN) {
        toast.success("Access Granted");
        auth.login(formValues.token);
        history.push("/dashboard");
        setBtnLoader(false);
      } else {
        setBtnLoader(false);
        showHelperText(true);
        setColors("primary");
        toast.error("Access Denied");

      }
    }
  };

  const KeyIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <g
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        >
          <circle cx="8" cy="15" r="4" />
          <path d="M10.85 12.15L19 4m-1 1l2 2m-5 1l2 2" />
        </g>
      </svg>
    );
  };
  const KeyOffIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <g
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        >
          <circle cx="8" cy="15" r="4" />
          <path d="m10.85 12.15l.65-.65m2-2L19 4m-1 1l2 2m-5 1l2 2M3 3l18 18" />
        </g>
      </svg>
    );
  };

  return (
    <MuiThemeProvider theme={getMuiTheme}>
      <div className={classes.box}>
        <Grid container spacing={3}>
          <Grid item xs={0} sm={12} md={6}>
            <Hidden smDown>
              <Paper
                elevation={0}
                className={`${classes.paper} ${classes.adminbg}`}
              >
                <LoginImage />
                <Typography className={classes.loginText} variant="h5">
                  Welcome to JM Tech Admin
                </Typography>
                <Typography className={classes.loginminitext} variant="p">
                  Provide the token to view all applicant details for the
                  Microsoft Future Ready Program
                </Typography>
                <div>
                  <LoginBg />
                </div>
              </Paper>
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper elevation={0} className={classes.paper}>
              <div>
                <img width={"150px"} src={newlogo} alt="JM Tech Logo" />
              </div>
              <br />
              <br />
              <Grid item xs={8} sm={8}></Grid>

              <Typography
                style={{ fontWeight: "500", fontSize: "20px" }}
                variant="p"
                color="secondary"
              >
                {hrs < 12
                  ? "Good Morning!"
                  : hrs >= 12 && hrs < 16
                  ? "Good Afternoon!"
                  : "Good Evening!"}
              </Typography>
              <p>Let's see if there are any updates today</p>
              <div>
                <br />
                <br />
                <FormControl variant="outlined">
                  <form>
                    <InputLabel
                      color="secondary"
                      htmlFor="outlined-adornment-password"
                    >
                      Token
                    </InputLabel>
                    <OutlinedInput
                      error={true ? helperText === true : false}
                      color={colors}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={formValues.password}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onKeyDown={(e)=>{handleEnterKey(e)}}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <KeyIcon /> : <KeyOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                    {helperText === true ? (
                      <FormHelperText id="outlined-weight-helper-text">
                        Invalid Token
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </form>

                  <br />
                  <br />

                  <Button
                    disableElevation
                    className={classes.loginbtn}
                    variant="contained"
                    onClick={handleLogin}
                  >
                    {btnLoader === true ? (
                      <CircularProgress color="#ffff"></CircularProgress>
                    ) : (
                      "Login"
                    )}
                  </Button>

                  <ToastContainer theme="dark" />
                </FormControl>
              </div>
              <br />
              <br />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </MuiThemeProvider>
  );
};

export default withRouter(AdminLogin);
