import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            
              <div>
                <HeaderInfo>
                  <h1 className="font35 extraBold">Let's get in touch</h1>
                  <p className="font13">
                    Connect with us on our social media platforms and via our
                    email.
                    <br/><br/>
                    <a href="https://www.facebook.com/jmtechcentre" rel="noopener noreferrer" target={"_blank"} style={{marginRight:'10px'}}>
                    <iconify-icon
                      icon="ri:facebook-circle-fill"
                      width="30"
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        padding:'10px',
                        border:'1px soild rgba(255,255,255,0.4)',
                        borderRadius:'30px'
                      }}
                    ></iconify-icon>
                    </a>
                  
                    <a href="https://www.instagram.com/accounts/login/?next=/jmtechcentre/" rel="noopener noreferrer" target={"_blank"} style={{marginRight:'10px'}}>
                    <iconify-icon
                      icon="uil:instagram-alt"
                      width="30"
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        padding:'10px',
                        border:'1px soild rgba(255,255,255,0.4)',
                        borderRadius:'30px'

                      }}
                    ></iconify-icon>
                    </a>
                    <a href="https://www.linkedin.com/company/jm-tech-centre/" rel="noopener noreferrer" target={"_blank"} style={{marginRight:'10px'}}>
                    <iconify-icon
                      icon="akar-icons:linkedin-v1-fill"
                      width="30"
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        padding:'10px',
                        border:'1px soild rgba(255,255,255,0.4)',
                        borderRadius:'30px'

                      }}
                    ></iconify-icon>
                    </a>
                   
                  
                  </p>
                </HeaderInfo>
                <HeaderInfo>
                  <h3 className="font35 extraBold">Contact Us</h3>
                  <p className="font13">
                    <p>Phone: +234 806 039 5407</p>
                    <p>Email: skillslab@jmtechcenter.org</p>
                  </p>
                </HeaderInfo>
              </div>
           
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <span style={{color:'#fe0000'}}className="font13">JMTech Centre</span> All Right
              Reserved
            </StyleP>

            <Link
              className="whiteColor animate pointer font13"
              to="home"
              smooth={true}
              offset={-80}
            >
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 80px
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const HeaderInfo = styled.div`
  padding: 30px 0;
  color: #fff;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
