import React, { Fragment } from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
import ProjectImg7 from "../../assets/img/projects/7.png";
import ProjectImg8 from "../../assets/img/projects/8.png";


export default function Projects() {
  return (
    <>
      <Wrapper id="projects">
        <div className="whiteBg" >
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Major Areas of Focus</h1>
              <p className="font13">
                Through various collaborations, we are able to provide a range
                of courses for Nigerians and Africans round the world
              </p>
            </HeaderInfo>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={ProjectImg2}
                  title="Machine Learning & Artificial Intelligence"
                  // action={() => alert("clicked")}
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={ProjectImg1}
                  title="Embedded Coding & Robotics"
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox img={ProjectImg3} title="Blockchain Technology" />
              </div>
            </div>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={ProjectImg4}
                  title="Computer Simulation & Gaming"
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={ProjectImg5}
                  title="Web Development (Frontend and Backend)"
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={ProjectImg6}
                  title="Cyber Security Solutions"
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={ProjectImg8}
                  title="Office Productivity Application"
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={ProjectImg7}
                  title="Entrepreneurship and Leadership"
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;


