import React from "react";
import styled from "styled-components";
// Components
// Assets
// import RollerIcon from "../../assets/svg/Services/RollerIcon";
// import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
// import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
// import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import CheckMark from "../../assets/svg/Checkmark";

export default function Objectives({ icon, price, title, text,  offers, action }) {
  // let getIcon;

  // switch (icon) {
  //   case "roller":
  //     getIcon = <RollerIcon />
  //     break;
  //   case "monitor":
  //     getIcon = <MonitorIcon />
  //     break;
  //   case "browser":
  //     getIcon = <BrowserIcon />
  //     break;
  //   case "printer":
  //     getIcon = <PrinterIcon />
  //     break;
  //   default:
  //     getIcon = <RollerIcon />
  //     break;
  // }

  return (
    <Wrapper className="whiteBg radius8">
      <div>
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                  {item.cheked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                  )}
                </div>
                <p className="font13">{item.name}</p>
              </div>
            ))
          : null}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
`;
