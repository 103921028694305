import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
// Components
// Assets
import JMLogoIcon from "../../assets/svg/JMTechLogo";


export default function OtherNavbar() {
  const [y, setY] = useState(window.scrollY);
  const history = useHistory()

  const goToApplication = () =>{
    history.push("/home");
  }


  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
            <Link to="/">
            <JMLogoIcon />
            </Link>
            
         
          <li className="semiBold font15 pointer flexCenter">
          <span className="radius8 lightBg" style={{ padding: "10px 15px", color:'#fe0000', border:'1px solid #fe0000' }} onClick={goToApplication}>
               Apply Here
              </span>
            </li>
          
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`



