import { Route, BrowserRouter, Switch } from "react-router-dom";
import {React} from 'react';
import Home from "./pages/index";
import Landing from "./screens/Landing.jsx";
import Applicants from "./pages/Table";
import GetAllPaidApplicantTable from "./pages/PaidApplicants";
import ApplicationClosed from "./pages/ApplicationClosed";
import PaymentDetails from "./pages/PaymentDetails";
// import QueryPayment from "./pages/QueryPayment";
import PayLater from "./pages/PayLater";
import SuccessfulPayment from "./pages/SuccessfulPayment";
import PayLaterResponse from "./pages/PayLaterResponse";
import NotFoundPage from "./pages/NotFound";
import TestWebPage from "./pages/TestWebPage";
import AdminLogin from "./pages/AdminLogin";
import AdminDashboard from "./pages/AdminDashboard";
import { AuthProvider } from "./auth";
import RequireAuth from "./components/RequireAuth";
import Onboarding from "./pages/Onboarding";
import BulkRegistration from "./pages/BulkRegistration";

// import { Helmet } from "react-helmet";
export default function App() {
  // const [isAuth, setIsAuth] = useState(true);

  return (
    <AuthProvider>
          <div
      style={{
        overflowY: "auto",
        overflowX: "hidden"
      }}
    >
      {/* <AuthenticationForm  isAuth = {isAuth} setIsAuth = {setIsAuth}/> */}

      <BrowserRouter>
      <Switch>
        <Route exact path="/home"  component={Home} />
        <Route exact path="/bulkRegistration"  component={BulkRegistration} />
        <Route exact path="/"  component={Landing} />
        <Route path = "/onboarding" component={Onboarding}/>
          <RequireAuth path="/applicants" component={Applicants}/>
          <RequireAuth path ="/registeredapplicants" component ={GetAllPaidApplicantTable}/>
          <RequireAuth path="/dashboard/" component={(AdminDashboard)}/>
          <RequireAuth path="/paymentDetails" component={PaymentDetails}/>
          {/* <RequireAuth path="/queryPayment/:id" component={QueryPayment}/> */}
          <Route path="/admin" component={AdminLogin}/>
         
         
          <Route path="/applicationClosed" exact component={ApplicationClosed} />
          {/* <Route path= "/payment/:email" component={Payment} /> */}
          {/* <Route path= "/payment" component={Payment} /> */}
          <Route path= "/resumeApplication" component={PayLater} />
          <Route path="/paymentresponse" component={SuccessfulPayment}/>
          <Route path="/payLater" component={PayLaterResponse}/>
          <Route path="/404"  component={NotFoundPage} />
          <Route path="/testpage"  component={TestWebPage} />
        
          {/* <Route path="/dashboard" component={AdminDashboard}/> */}
          {/* <Redirect from='/' to = '/404' exact/> */}
          <Route path="*"  component={NotFoundPage} /> 
          

      </Switch>
       
      </BrowserRouter>
    </div>
    </AuthProvider>
  );
}