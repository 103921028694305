import React from "react";
import styled from "styled-components";
// Components
import Objectives from "../Elements/PricingTable";
import ContactImg3 from "../../assets/img/contact-3.png";

export default function Pricing() {
  return (
    <>
    <br/><br/>
     <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          {/* <HeaderInfo> */}
          <div
            className="row"
            style={{  alignItems: "center" }}
          >
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <h1 className="font40 extraBold">
                Core Objectives of JM Tech Centre
              </h1>
              {/* </HeaderInfo> */}
              <TablesWrapper>
                <TableBox>
                  <Objectives
                    offers={[
                      {
                        name: "Build IT Capacity particularly for Nigeria and Africa",
                        cheked: true,
                      },
                      {
                        name: "Develop IT Entrepreneurs and Businesses",
                        cheked: true,
                      },
                      {
                        name: "Position IT for acceleration of national socio-economic development (accelerate digital economy)",
                        cheked: true,
                      },
                      {
                        name: "Accelerate Digital Transformation, Good Practices for Developing and Driving IT Centric Innovations",
                        cheked: true,
                      },
                      {
                        name: "Create a Platform for Collaboration",
                        cheked: true,
                      },
                    ]}
                  />
                </TableBox>
              </TablesWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex" style={{justifyContent:'center'}}>
              <div style={{ width: "50%", display:'flex', justifyContent:'center' }}>
                <div>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
    <br/>
    </>
   
  );
}

const Wrapper = styled.section`
  width: 100%;
 
`;

const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 100%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;


