import React, { Fragment } from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// Assets
import ProjectImg1 from "../../assets/img/courses/2.png";
import ProjectImg2 from "../../assets/img/courses/1.png";
import ProjectImg3 from "../../assets/img/courses/3.png";
import ProjectImg4 from "../../assets/img/courses/4.png";
import { Link } from "react-router-dom";

export default function Courses() {
  return (
    <>
      <Wrapper id="courses">
        <div className="whiteBg" >
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Courses</h1>
              <p>Each learning path consists of step-by-step tutorials that allow participants track progress, check knowledge, and validate deployments to earn points, levels, achievements and trophies, exciting right? To get started on the right learning path, <Link to="home" style={{color:'#fe0000', fontWeight:600, textDecoration
            : 'underline'}}>apply here</Link>.</p>
            </HeaderInfo>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <ProjectBox
                  img={ProjectImg2}
                  title="Computer Science"
                  text= "Coding skills (Frontend and Backend) based on the latest tools and techniques."
                  // action={() => alert("clicked")}
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <ProjectBox
                  img={ProjectImg1}
                  title="IT Infrastructure"
                  text= "Skills in IT administration and cloud platform solutions."

                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <ProjectBox
                  img={ProjectImg3}
                  title="Data Science"
                  text= "Introduction to data science concepts and tools."

                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <ProjectBox
                  img={ProjectImg4}
                  title="Office Productivity Applications"
                  text= "Mastery of the productivity applications broadly used in business"

                />
              </div>
            </div>
            </div>
          </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
