import React from "react";
import { useLocation } from 'react-router-dom';



const TestWebPage = () =>{
    
   const {search} = useLocation();
   const searchParams = new URLSearchParams(search)
   const refcode = searchParams.get('reference')

    return(
        <div>
        {refcode}
        </div>
    )      

}

export default TestWebPage;