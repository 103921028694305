import React, { useState, useEffect } from "react";
import axios from "axios";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "../constants/apiUrl";
import { CircularProgress } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    transition: "all 0.5s",
    "&:hover": {
      paddingRight: "30px",
      paddingLeft: "30px",

      color: "#FFF",
      transition: "0.5s",
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ProceedToPayment = (props) => {
  const { openPay, emailAddressHolder, responseFirstName, responseLastName } =
    props;

  const [responseMsg, setResponseMsg] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  // const [responseData, setResponseData] = useState("");
  // const [refNumber, setRefNumber] = useState(null);

  const classes = useStyles();
  const fullname = responseFirstName + " " + responseLastName;
  const [amount, setAmount] = useState(0);

  const initialFormValue = {
    initialtoken: "1A2B5E",
    token: "",
    amount: 30550,
  };

  const history = useHistory();

  const goToPayLater = () => {
    history.push("/paylater", { fullname, emailAddressHolder });
  };

  const handleClosePayLater = () => {
    history.push("/paylater", { fullname, emailAddressHolder });
  };

  const payLaterHandler = () => {
    let formData = new FormData();
    formData.append("emailAddress", emailAddressHolder);
    formData.append("fullName", responseFirstName);
    if (
      responseFirstName !== "" &&
      responseLastName !== "" &&
      emailAddressHolder !== ""
    ) {
      axios
        .post(
          `${baseURL}api/Applicant/PayLater?emailAddress=${emailAddressHolder}&fullName=${fullname}`
        )
        .then(function (response) {
          setResponseMsg(response.data.Msg);
          goToPayLater();
        })
        .catch(function (error) {
          toast.error(responseMsg);
        })
        .then(function () {});
    } else {
    }
  };

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}api/Training/GetTrainingAmount?trainingname=1`)
        .then(function (response) {
          console.log(response);
          if (response.data) {
            const amountSet = response.data.Data[0].TrainingAmount;
            setAmount(amountSet);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const proceedToPayBtn = () => {
    setButtonLoader(true);
    try {
      axios
        .get(`${baseURL}api/Training/GetTrainingAmount?trainingname=1`)
        .then(function (response) {
          console.log(response);
          if (response.data) {
            const amountSet = response.data.Data[0].TrainingAmount;
            axios
              .post(`${baseURL}api/Applicant/ProcessPayment`, {
                accountName: fullname,
                amount: amountSet,
                accountEmail: emailAddressHolder,
                url: "https://jmtechcenter.org/paymentresponse",
              })
              .then(function (response) {
                console.log(response, "Console logging response outside");
                if (response.data) {
                  console.log(
                    response.data,
                    "Console logging response inside if statement"
                  );
                  // setResponseData(response.data.Data);
                  window.location.href = `${response.data.Msg}`;
                }
              })
              .catch(function (error) {
                console.log(error);
                setButtonLoader(false);
              });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const items = emailAddressHolder;

  useEffect(() => {
    localStorage.setItem("items", JSON.stringify(items));
  }, [items]);

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
      Open dialog
    </Button> */}
      <Dialog
        onClose={handleClosePayLater}
        aria-labelledby="customized-dialog-title"
        open={openPay}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClosePayLater}>
          Proceed To Payment
        </DialogTitle>
        <DialogContent dividers>
          To complete registration, you have to make a total payment of{" "}
          <span style={{ color: "#fe0000" }}>&#8358;{amount}</span>.
          <p>Do you wish to proceed with the payment?</p>
          <br />
          <br />
          <div>
            <table
              width={500}
              style={{
                borderCollapse: "collapse",
                backgroundColor: "#EEEEEE90",
              }}
            >
              <tr>
                <td style={{ padding: "8px" }}>
                  Registration Fee: <b>&#8358;30,000</b>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#FFE1E190" }}>
                {" "}
                <td style={{ padding: "8px" }}>
                  Charges Fee: <b>&#8358;550</b>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "8px" }}>
                  Total Amount: <b>&#8358;30,550</b>
                </td>
              </tr>
            </table>
          </div>
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.submitBtn}
            onClick={payLaterHandler}
            style={{
              backgroundColor: "#94949485",
              color: "#fff",
              textTransform: "capitalize",
            }}
          >
            Pay Later
          </Button>
          <Button
            className={classes.submitBtn}
            onClick={proceedToPayBtn}
            style={{
              backgroundColor: "#00a1f1",
              color: "#fff",
              textTransform: "capitalize",
            }}
          >
            {!buttonLoader ? "Pay Now" : <CircularProgress size={17} />}
          </Button>

          <ToastContainer />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProceedToPayment;
