import React, { useEffect, useState } from "react";
import moment from "moment";
import { ExitToApp, PeopleAlt } from "@material-ui/icons";
import GetAllPaidApplicantTable from "./PaidApplicants";
import { Link, withRouter, useHistory } from "react-router-dom";
import {useAuth} from "../auth";
import {
  MuiThemeProvider,
  makeStyles,
  withStyles,
  LinearProgress,
  Typography,
  createTheme,
  Grid,
  Paper,
  Box,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../constants/apiUrl";

const getMuiTheme = createTheme({
  palette: {
    secondary: { main: "#1a1a1a" },
    primary: { main: "#fe0000" },
  },
  overrides: {},
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  topdiv: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(3),
  },
  rightgrid: {
    backgroundColor: "#F5F5F5",
    zIndex:'999',
    // height: "100vh",
    
  },
  tablediv: {boxSizing:'border-box'},
  rightdiv: {
   padding:'20px',
    backgroundColor: "#F5F5F5",
  
  },
  middlediv: {
    paddingLeft: theme.spacing(5),
    paddingRight:theme.spacing(5),
  },
  box: {
    // height:'fit-content',
    padding: theme.spacing(1),
    boxSizing:'border-box',
    height: "100vh",
    // overflow:'hidden',
    // overflow:'hidden',
    [theme.breakpoints.only("xs")]:{
      overflowY:'auto'
    }
  },
  button: {
    textTransform: "capitalize",
    background: "none",
    "&:hover": {
      background: "none",
      color: "#fe0000",
    },
  },
  title: {
    color: "#1a1a1a",
    fontSize: "12px",
  },
  statscard: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    color: "#1a1a1a",
  },
  allapplicant: {
    backgroundColor: "#EAFFF5",
  },
  male: {
    backgroundColor: "#E7F2FF",
  },
  female: {
    backgroundColor: "#FFE6EF",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const FemaleBorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    //   backgroundColor: "#FF699F",
    backgroundColor: "#fe0000",
  },
}))(LinearProgress);

const AdminDashboard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();
  let currentdate = moment(new Date()).format("MMMM DD YYYY");
  const [allApplicantStats, setAllApplicantStats] = useState([]);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const handleLogout = () =>{
    auth.logout();
    history.push("/admin")    
  }

  const fetchAllApplicantStats = (body) => {
  

    if(navigator.onLine === true){
    axios
      .get(
        `${baseURL}api/Applicant/GetApplicantStats`,
        body
      )
      .then(function (response) {
        // handle success
        setAllApplicantStats(response.data.Data);
        // setLoaderPage(false);
      })
      .catch(function (error) {
        // handle error
        // alert('Error occured in loading All Data');
      })
      .then(function () {
        // always executed
      });
    }else{
      toast.error("No Internet, Check your connection")
    }
  };


  useEffect(()=>{
    fetchAllApplicantStats();
  },[])

  useEffect(()=>{
    if(allApplicantStats[0]!== undefined){
      setMale(Math.round((allApplicantStats[0].TotalMaleApplicants)/(allApplicantStats[0].TotalApplicants)*(100)));
      setFemale(Math.round((allApplicantStats[0].TotalFemaleApplicant)/(allApplicantStats[0].TotalApplicants)*(100)));
    }
  }, [allApplicantStats, male, female])
  
 


  return (
    <MuiThemeProvider theme={getMuiTheme}>
      <Box className={classes.box}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <Paper elevation={0} className={classes.topdiv}>
              <div style={{display:'flex', justifyContent:'space-between'}}>
              <Typography
                variant="h6"
                style={{ color: "#1a1a1a", fontWeight: "600" }}
              >
                Welcome Admin !
              </Typography>
              <Button
                onClick={()=>handleLogout()}
                variant="contained"
                disableElevation
                className={classes.button}
                startIcon={<ExitToApp />}
              >
                Logout
                </Button>
              </div>
           
              <Typography style={{ color: "#A5A5A5", fontSize: "15px" }}>
                Today's Date: {currentdate}{" "}
              </Typography>
            </Paper>

            <Paper elevation={0} className={classes.middlediv}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Card
                    elevation={0}
                    className={`${classes.card} ${classes.allapplicant}`}
                  >
                    <div>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          All Applicants
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h2"
                          style={{ fontWeight: "600" }}
                        >
                          {allApplicantStats[0]?.TotalApplicants}
                        </Typography>
                      </CardContent>
                    </div>
                    <div style={{ alignSelf: "center", paddingRight: "20px" }}>
                      <PeopleAlt style={{ color: "#00924C" }} />
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card
                    elevation={0}
                    className={`${classes.card} ${classes.male}`}
                  >
                    <div>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Male
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h2"
                          style={{ fontWeight: "600" }}
                        >
                             {allApplicantStats[0]?.TotalMaleApplicants}
                        </Typography>
                      </CardContent>
                    </div>
                    <div style={{ alignSelf: "center", paddingRight: "20px" }}>
                      <span
                        style={{ color: "#5992D6" }}
                        data-width="30"
                        class="iconify"
                        data-icon="ic:round-male"
                      ></span>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card
                    elevation={0}
                    className={`${classes.card} ${classes.female}`}
                  >
                    <div>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                        Female
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h2"
                          style={{ fontWeight: "600" }}
                        >
                            {allApplicantStats[0]?.TotalFemaleApplicant}
                        </Typography>
                      </CardContent>
                    </div>
                    <div style={{ alignSelf: "center", paddingRight: "20px" }}>
                      <span
                        class="iconify"
                        data-icon="icon-park-outline:female"
                        style={{ color: "#ff699f" }}
                        data-width="30"
                      ></span>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={0} className={classes.tablediv}>
              <GetAllPaidApplicantTable />
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} className={classes.rightgrid} >
            <Paper elevation={0} className={classes.rightdiv}>
            
                <div style={{ display: "flex", flexDirection: "column" }} >
              <Paper elevation={0} className={classes.rightdiv} >
                <Paper
                  elevation={0}
                  style={{ padding: "20px", borderRadius: "10px",}}
                >
                  <Typography
                    style={{
                      color: "#1a1a1a",
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginBottom: "25px",
                    }}
                  >
                    Statistics
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    <div>Male</div>
                    <div>{male}%</div>
                  </div>
                  <BorderLinearProgress variant="determinate" value={male} />
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    <div>Female</div>
                    <div>{female}%</div>
                  </div>
                  <FemaleBorderLinearProgress
                    variant="determinate"
                    value={female}
                  />
                </Paper>
                <br />
                <br />
                <Paper
                  elevation={0}
                  style={{
                    padding: "20px",
                    borderRadius: "10px",
                    backgroundColor: "#333333",
                  }}
                >
                  <Typography
                    variant="p"
                    style={{ color: "#fff", fontSize: "12px" }}
                  >
                    <div></div>
                    Balance
                  </Typography>
                  <Typography variant="h5" style={{ color: "#fff" }}>
                    &#8358; not updated
                  </Typography>
                </Paper>
                <br />
                <br />
                <Paper
                  elevation={0}
                  style={{
                    fontWeight: "600",
                    backgroundColor: "#fe0000",
                    padding: "20px",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                  <Link to="/applicants" style={{ color: "#fff" }}>
                    View All Applicants
                  </Link>
                  
                </Paper>
                <br/><br/>
                
                <Paper
                  elevation={0}
                  style={{
                    fontWeight: "600",
                    backgroundColor: "#00A8E0",
                    padding: "20px",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                 
                  <Link to="/paymentDetails" style={{ color: "#fff" }}>
                    View Payment Details
                  </Link>
                  
                </Paper>
              </Paper>
            </div>
            </Paper>
          
          </Grid>
        </Grid>
      </Box>
    </MuiThemeProvider>
  );
};

export default withRouter(AdminDashboard);
