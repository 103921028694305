import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useHistory } from "react-router";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) => ({
  selectBoxes: {
    borderRadius: "5px",
    padding: "15px 20px",
    border: "1px solid #7f7f7f",
    cursor:'pointer'
  },
  content: {
    padding: "20px",
  },
  proceedBtn: {
    textTransform: "initial",
    background:'#1a1a1a'
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, display:'flex', justifyContent:'space-between', alignItems:'center' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function RegisterDialog({ open, handleClose, handleProceed }) {
  const classes = useStyles();
  const singleRef = React.useRef();
  const multipleRef = React.useRef();
  const history = useHistory();
  const [value, setValue]= React.useState('');

  const handleRadio = (e) =>{
    setValue(e.target.value)
  }

  const proceedToBulkReg = (e)=>{
    history.push('/bulkRegistration')

  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Start Your Application
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FormControl
            component="fieldset"
            className={classes.content}
          >
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              onChange={handleRadio}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl className={classes.selectBoxes}   onClick={() => singleRef.current.click()}>
                    <FormControlLabel
                      value="single"
                      control={<Radio color="primary" ref={singleRef} />}
                      label="Apply as an Individual"
                    />
                    <Typography variant="subtitle2">
                      I am enrolling to partake in this program
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.selectBoxes} onClick={() => multipleRef.current.click()} >
                    <FormControlLabel
                      value="multiple"
                      control={<Radio color="primary" ref={multipleRef}/>}
                      label="Apply as a group"
                    />
                    <Typography variant="subtitle2">
                      I am enrolling for a group of people to partake in this
                      program
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.proceedBtn}
            disableElevation
            variant="contained"
            autoFocus
            onClick={value==="single"?handleProceed: value==="multiple"?proceedToBulkReg:""}
            sx={{textTransform:'initial', m:'5px'}}
          >
            Proceed
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
