import React, { useState, useEffect, useCallback } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import PropTypes from "prop-types";
import axios from "axios";
import "./style.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Folder from "../assets/folder.png";
import CloseIcon from "@mui/icons-material/Close";
import {
  makeStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import { Button, Grid, IconButton, Paper, TextField } from "@material-ui/core";
import newlogo from "../assets/newlogo.png";
import bg from "../assets/bg.jpg";
import FormControl from "@material-ui/core/FormControl";
import { CircularProgress } from "@material-ui/core";
import ProceedToPayForm from "./ProceedToPayForm";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "../constants/apiUrl";
import RegisterDialog from "./RegisterDialog";
import Dropzone, { useDropzone } from "react-dropzone";
const getMuiTheme = createTheme({
  palette: {
    secondary: { main: "#1a1a1a" },
    primary: { main: "#fe0000" },
    background: {},
  },

  overrides: {
    MuiToolbar: {
      regular: {
        justifyContent: "space-between",
      },
    },
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        },
      },
    },
    MuiGrid: {
      container: {
        display: "block",
      },
    },
    "& body": {
      backgroundColor: "red !important",
    },
    MuiFormControl: {
      root: {},
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.secondary,
    transition: "all easein 1s",
    marginBottom: "50px",
    [theme.breakpoints.only("xs")]: {
      padding: "0px !important",
    },
  },
  papers: {
    padding: theme.spacing(5),
    textAlign: "center",
    color: theme.palette.text.secondary,
    transition: "all easein 1s",
    backgroundColor: "rgba(255,255,255,0.95)",
    [theme.breakpoints.only("xs")]: {
      marginTop: "0px !important",
      fontSize: "9px",
      padding: "0px",
    },
  },
  ApplyHereBtn: {
    textTransform: "capitalize",
  },
  formText: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.4em",
    },
  },
  Logo: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  dragdropZone: {
    padding: "20px",
    borderRadius: "10px",
    border: "2px dashed #c5c5c5",
    alignItems: "center",
  },
  dragdropText: {
    color: "#7899D9",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      textDecorationThickness: "2px",
    },
  },
  dragdropActive: {
    padding: "20px",
    borderRadius: "10px",
    border: "2px dashed #c5c5c5",
    alignItems: "center",
    backgroundColor: "#f1f7ff",
  },
  sampleTemplateButton: {
    textTransform: "initial",
    backgroundColor: "#9478d9",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#7e66b9",
    },
  },
  error: {
    color: "red",
  },
  filesUploadedZone: {
    backgroundColor: "#EDEFF1",
    marginTop: "20px",
    textAlign: "left",
    fontWeight: "600px",
    padding: "20px",
    borderRadius: "10px",
  },
  fileNameSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function BulkRegistration(props) {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [bulkFile, setBulkFile] = useState({
    name: "",
    path: "",
    size: "",
    display: false,
    error: false,
  });
  const classes = useStyles();
  const handleDeleteFile = () => {
    setBulkFile({
      ...bulkFile,
      name: "",
      path: "",
      size: "",
      display: false,
    });
  };


  const myExcelFile = require('../excelfile/JMTECH APPLICATION FORM.xlsx');

  function handleDownload() {
    const link = document.createElement('a');
    link.href = myExcelFile.default;
    link.download = 'JMTECH APPLICATION TEMPLATE.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
    
  
  function MyDropzone() {
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          console.log(binaryStr, "binarystring");
          console.log(reader, "reader");
          console.log(file, "file");
          if (!file.name.match(/\.(xlsx)$/)) {
            setBulkFile({ ...bulkFile, error: true });
            return false;
          } else {
            setBulkFile({
              ...bulkFile,
              name: file.name,
              size: file.size,
              display: true,
              error:false,
            });
          }
        };
        reader.readAsArrayBuffer(file);
        console.log(reader.readAsArrayBuffer(file), "as array buffer");
      });
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
    });

    return (
      <div
        className={isDragActive ? classes.dragdropActive : classes.dragdropZone}
        {...getRootProps()}
      >
        <div>
          <img src={Folder} alt="folder" width="70px" height="50px" />
        </div>
        <br />
        <input {...getInputProps()} />
        {isDragActive ? (
          <b>Drop files here...</b>
        ) : (
          <b>
            Drag & Drop or{" "}
            <span className={classes.dragdropText}>Choose file</span> to upload
          </b>
        )}
        <br />
        <br />
        <p>XLSX</p>
      </div>
    );
  }

  const logoFunction = () => {};

  useEffect(()=>{
   setTimeout(()=>{
    setLoader(false)
   }, [500]) 
  })
  return (
    <React.Fragment>
      <div style={{ backgroundColor: "red !important" }}>
        <CssBaseline />
        {loader === true ? (
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <MuiThemeProvider theme={getMuiTheme}>
            <CssBaseline />
            <AppBar
              position="sticky"
              style={{
                backgroundColor: "#fff",
                height: "90px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Toolbar>
                <img
                  className={classes.Logo}
                  onClick={logoFunction}
                  src={newlogo}
                  alt="JM Tech Centre"
                  width="130px"
                  height="70px"
                />
              </Toolbar>
            </AppBar>
            <Toolbar id="back-to-top-anchor" />
            <Container>
              <Box my={2}>
                <Grid container spacing={3}>
                  <Paper className={classes.paper}>
                    <FormControl>
                      <div style={{ marginBottom: "20px" }}>
                        <h2 className={classes.formText}>
                          Application Form For Microsoft Future Ready Program
                        </h2>

                        <h4 style={{ fontStyle: "italic" }}>
                          <span style={{ color: "#fe000067" }}>Note: </span>A
                          payment of <>&#8358;</>30,000.00 will be required for
                          each applicant to complete this registration
                        </h4>
                      </div>
                      <div style={{ marginBottom: "20px" }}>
                        <p>
                          Register for a group of people by uploading a
                          spreadsheet template containing all the neccessary
                          details of the applicants. <br />
                          Download the spreadsheet template below, fill it and
                          then upload
                        </p>
                        <br />
                        <Button
                          onClick={handleDownload}
                          disableElevation
                          variant="contained"
                          className={classes.sampleTemplateButton}
                          startIcon={<GetAppIcon />}
                        >
                          Download Sample Template
                        </Button>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        {bulkFile.error ? (
                          <p className={classes.error}>
                            You can only upload file in .CSV or .XLSX format{" "}
                          </p>
                        ) : (
                          ""
                        )}
                        <MyDropzone />
                      </div>
                      {bulkFile.display ? (
                        <div className={classes.filesUploadedZone}>
                          <div className={classes.fileNameSection}>
                            <div>
                              <b>{bulkFile.name}</b>
                              <p>{Math.round(bulkFile.size / 1024)} KB</p>
                            </div>
                            <div>
                              <IconButton onClick={handleDeleteFile}>
                                <CloseIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          marginTop: "30px",
                        }}
                      >
                        <Button
                          /*   onClick={CancelBtnHandler} */
                          style={{ marginRight: "20px" }}
                          variant="outlined"
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          //   onClick={registerHandler}
                          style={{ flexGrow: "1" }}
                          variant="contained"
                          color="secondary"
                        >
                          {submitLoader === true ? (
                            <CircularProgress />
                          ) : (
                            "Submit"
                          )}
                          {/* Submit */}
                        </Button>
                      </div>
                    </FormControl>
                  </Paper>
                </Grid>
              </Box>
            </Container>
            <ScrollTop {...props}>
              <Fab
                color="secondary"
                size="small"
                aria-label="scroll back to top"
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </ScrollTop>
          </MuiThemeProvider>
        )}
      </div>
    </React.Fragment>
  );
}
