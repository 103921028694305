import React from "react";
import styled from "styled-components";
// Components
import Objectives from "../Elements/PricingTable";

export default function Benefits() {
  return (
    <>
    <br/><br/>
     <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          {/* <HeaderInfo> */}
          <h1 className="font40 extraBold">
              Benefits
              </h1>
          <div
            className="row"
            style={{  alignItems: "center" }}
          >
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
           
              {/* </HeaderInfo> */}
              <TablesWrapper>
                <TableBox>
                  <Objectives
                    offers={[
                      {
                        name: "Development of the right tech skillset that makes you employable immediately after graduation through a well-coordinated learning program path.",
                        cheked: true,
                      },
                      {
                        name: "Microsoft Certification through completion of learning resources allocated to this program, to boost your knowledge and skill.",
                        cheked: true,
                      },
                      {
                        name: "One (1) complimentary voucher for your certification examination.",
                        cheked: true,
                      },
                      {
                        name: "Accelerate Digital Transformation, Good Practices for Developing and Driving IT Centric Innovations",
                        cheked: true,
                      },
                      {
                        name: "Access to the JM Tech Centre Facility after the program for more engaging and immersive learning.",
                        cheked: true,
                      },
                      {
                        name: "Opportunity to work on practical projects to develop expertise.",
                        cheked: true,
                      },
                      
                      {
                        name: "Access to mentors and periodic seminars from successful industry professionals and top-ranking citizens.",
                        cheked: true,
                      },
                      
                    ]}
                  />
                </TableBox>
              </TablesWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex" style={{justifyContent:'center'}}>
            <TablesWrapper>
                <TableBox>
                  <Objectives
                    offers={[
                
                      {
                        name: "24/7 Access to the learning platform to learn at your pace.",
                        cheked: true,
                      },
                      {
                        name: "A very good way to engage yourself during holidays and unforeseen closure of schools.",
                        cheked: true,
                      },
                      {
                        name: "Connect with other students (globally) who share the same passion with you in focused Leagues and use tech to solve real-world challenges together.",
                        cheked: true,
                      },
                      {
                        name: "  Take your creativity to the world stage through the opportunity of participating in the Microsoft Imagine Cup.",
                        cheked: true,
                      },
                      {
                        name: " As an educator (teacher or lecturer), you will get resources and training to enhance the classroom experience by delivering cutting-edge technology instructions.",
                        cheked: true,
                      },
                      {
                        name: "  As a tertiary institution, you will be equipped with a high-quality curriculum that is easy to administer and adds value to your students with certification.",
                        cheked: true,
                      },
                    ]}
                  />
                </TableBox>
              </TablesWrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
    <br/>
    </>
   
  );
}

const Wrapper = styled.section`
  width: 100%;
 
`;

const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 100%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;

