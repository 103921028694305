import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuth} from '../auth';


function RequireAuth({component:Component, ...rest}){
    const auth = useAuth();
    return(
        <Route 
        
        {...rest} 
         render={props=>{
            if (auth.user){
                return <Component {...props}/>
            }else{
                return <Redirect
                to={{
                  pathname:"",
                  state: { from: props.location }
                }}
              />
            }
            
            // }else{
            //    return <Redirect to={{pathName:'/', state={from: props.location} }}/>
            // }
        }}
        />
    );
}
export default RequireAuth;