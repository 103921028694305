import React from 'react';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme) => ({
  statusType: {
    padding: 3,
    textAlign: "center",
    borderRadius: 4,
    width: "90px",
  },
}));

const statusTypesDict = {
  redFilled: {
    border:"none",
    backgroundColor: "#FCE7E7",
    color: "#E79191",
  },
  redOutlined: {
    border: "2px solid #FCE7E7",
    backgroundColor: "transparent",
    color: "#E79191",
  },  
  redText: {
    color: "#a83244",
  }, 
  yellowFilled: {
    border: "none",
    backgroundColor: "#FBF9C4",
    color: "#FBBC05",
  },
  yellowOutlined: {
    border: "2px solid #FBF9C4",
    backgroundColor: "transparent",
    color: "#FBBC05",
  },  
  greenFilled: {
    border:"none",
    backgroundColor: "#D7FAD9",
    color: "#05A80B",
  },
  greenOutlined: {
    border:"2px solid #D7FAD9",
    backgroundColor: "transparent",
    color: "#05A80B",
  },
  greenText: {
    color: "#32a852",
  },
  orangeFilled:{
    backgroundColor:"#FFE2AD",
    color:'#CC8500',
    border:'none'
  },
  orangeOutlined:{
    backgroundColor:"transparent",
    color:'#CC8500',
    border:'2px solid #FFB833'
  },
  greyFilled:{
    backgroundColor:"#c5c7c3",
    color:'#5d5e5c',
    border:'none'
  },
  greyOutlined:{
    backgroundColor:"transparent",
    color:'#5d5e5c',
    border:'2px solid #c5c7c3'
  },
  greyText:{
    color:'#5d5e5c',
  },
}

const StatusGenerator = ({ statusText, variant }) => {
  /*
    variant types: [redFilled, redOutlined, yellowFilled, yellowOutlined, greenFilled, greenOutlined, ]
  */
  const classes = useStyles();
  
  return (
    <div 
      className={classes.statusType} 
      style={{
        backgroundColor: statusTypesDict[variant].backgroundColor,
        color: statusTypesDict[variant].color,
        border: statusTypesDict[variant].border,
      }}
    >
      {statusText}
    </div>
  )
  
}

export default StatusGenerator