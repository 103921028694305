import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import LoadingOverlay from 'react-loading-overlay-ts';
import axios from "axios";
import StatusGenerator from "./StatusGenerator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MuiThemeProvider,
  makeStyles,
  createTheme,
  Button,
  Grid,
  Box,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import moment from "moment";
import newlogo from "../assets/newlogo.png";
import { withRouter } from "react-router-dom";
import { baseURL } from "../constants/apiUrl";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";

const getMuiTheme = createTheme({
  palette: {
    secondary: { main: "#1a1a1a" },
    primary: { main: "#fe0000" },
    background: {
      // default:`url(${bg})`,
      // paper:"#fe0000",
    },
  },

  overrides: {
    MuiTableCell: {
      root: {
        padding: "2px 20px !important",
      },
    },
    MUIDataTableBodyRow: {
      root: {
        "&:nth-child(odd)": {
          backgroundColor: "#FFEBEB49",
        },
      },
    },
    MUIDataTableHeadCell: {
      data: {
        fontWeight: "600",
        color: "#1a1a1a",
      },
      fixedHeader: {
        textAlign: "left",
      },
    },
    MUIDataTableToolbar: {
      titleText: {
        color: "#fe0000",
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: "none",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(8),
    overflow: "hidden",
  },
  divTitle: {
    color: "#fff",
    fontWeight: "600",
    backgroundColor: "#00A8E0",
    display: "flex",
    padding: "20px",
    // height: "50px",
    borderRadius: "10px",
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  circularprogress: {
    color: "#00A8E0",
  },
  linkStyle: {
    color: "#5992D6",
    backgroundColor: "none",
    textTransform: "capitalize",
    textDecoration: "underline",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
}));

const PaymentDetails = () => {
  const classes = useStyles();
  const [loader, setLoader] = useState(true);
  const [loaderPage, setLoaderPage] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [paymentDataTable, setPaymentDataTable] = useState([]);
  const [paymentDataTableResponse, setPaymentDataTableResponse] = useState([]);

  const delimitNumber = (str) => {
    return (str + "").replace(/\b(\d+)((\.\d+)*)\b/g, function (a, b, c) {
      return (
        "₦" +
        (b.charAt(0) > 0 && !(c || ".").lastIndexOf(".")
          ? b.replace(/(\d)(?=(\d{3})+$)/g, "$1,")
          : b) +
        c
      );
    });
  };

  const updateSearchText = (text) => {
    // setIsLoading(true);
    setSearchText(text);
  };

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "scroll",
    selectableRows: "none",
    fixedHeader: true,
    selectableRowsHeader: false,
    rowsPerPageOptions: [10, 20, 50, 100, 200],
    elevation: 3,
    textLabels: {
      body: {
        noMatch: "Sorry, there is no matching data to display",
      },
    },
    onSearchChange: (searchText) => {
      updateSearchText(searchText);
    },
  };
  const columns = [
    {
      name: "ApplicantId",
      label: "Id",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return <span>{tableMeta.rowIndex + 1}</span>;
        },
      },
    },
    {
      name: "AccountName",
      label: "Account Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        customBodyRender: (value, tableMeta) => {
          return value === undefined || value === "" ? "null" : value;
        },
      },
    },
    {
      name: "AccountEmail",
      label: "Account Email",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: "100px" } }),
        customBodyRender: (value, tableMeta) => {
          return value === undefined || value === "" ? "null" : value;
        },
      },
    },
    {
      name: "TrxRef",
      label: "Reference Number",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        customBodyRender: (value, tableMeta) => {
          return value === undefined || value === "" ? "- " : value;
        },
      },
    },
    {
      name: "TransactionAmount",
      label: "Transaction Amount",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: "180px" } }),
        customBodyRender: (value, tableMeta) => {
          return value === undefined || value === ""
            ? "null"
            : delimitNumber(value);
        },
      },
    },
    {
      name: "TransactionStatus",
      label: "Transaction Status",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        customBodyRender: (value, tableMeta) => {
          // return value === undefined || value === "" || value === null? "null" : value;
          switch(value){
            case "success":
            return <StatusGenerator statusText="success" variant="greenFilled"/>
            case "abandoned":
            return <StatusGenerator statusText="abandoned" variant="redFilled"/>
            default:
            return "not verified"   
          }
        },
      },
    },
    {
      name: "GatewayResponse",
      label: "Gateway Response",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: "280px" } }),
        customBodyRender: (value, tableMeta) => {
        //   return value === undefined || value === "" || value === null
        //     ? "null"
        //     : value;
        switch(value){
            case "Approved":
            return <StatusGenerator statusText="Approved" variant="greenText"/>
            case "The transaction was not completed":
            return <p style={{color:'#a83244',marginLeft:'15px'}}>The transaction was not completed</p>
            case "Approved by Financial Institution":
            return <p style={{color:'#32a852',marginLeft:'15px'}}>Approved by Financial Institution</p>
            default:
            return <p style={{color:'#5d5e5c',marginLeft:'15px'}}>Payment was not verified</p>

          }
        },
      },
    },
    {
      name: "Channel",
      label: "Channel",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: "180px" } }),
        customBodyRender: (value, tableMeta) => {
          return value === undefined || value === "" || value === null
            ? "null"
            : value;
        },
      },
    },
    {
      name: "DateCreated",
      label: "Date Created",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: "180px" } }),
        customBodyRender: (value, tableMeta) => {
          return value === undefined || value === "" || value === null
            ? "no date found"
            : moment(value).format("MMMM DD YYYY");
        },
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "180px" } }),
        customBodyRender: (value, tableMeta) => {
          return (
            <span>
              <Button
                onClick={() => handleVerifyPayment(value.TrxRef)}
                className={classes.linkStyle}
              >
                Query Payment
              </Button>
            </span>
          );
        },
      },
    },
  ];

  //axios post

  const fetchPaymentDetails = async (body) => {
      setLoader(true)
    try {
      await axios
        .post(
          `${baseURL}api/Applicant/GetAllTransactions`,
          body
        )
        .then(function (response) {
          // handle success
          // setState(response.data.State)
          setPaymentDataTableResponse(response.data.Data);
          setLoader(false);
          setLoaderPage(false);
        })
        .catch(function (error) {
          // handle error
       
          // alert('Error occured in loading All Data');
          toast.error(error.response.data.Msg)
        })
        .then(function () {
          // always executed
        });
    } catch (error) {
      //    const err = error as AxiosError
      if (error.response) {
          toast.error(error.response.data.Msg)
      }
      // this.handleAxiosError(error);
      toast.error("An error occured, Check your connection!")
    }
  };

  useEffect(() => {
    let Datas = {
      search: searchText,
    };
    fetchPaymentDetails(Datas);
  }, [searchText]);

/*  const data =paymentDataTableResponse.filter((data) => data.TransactionStatus === "success")
    const ans = data.map((val) => {return val.TrxRef})
    console.log(ans, "Transaction"); */

  //effect populating table data
  useEffect(() => {
  
    const createPaymentTableData = () => {
      let paymentDataTable = [];
      paymentDataTableResponse.forEach((val) => {
        let row = [
          val,
          val.AccountName,
          val.AccountEmail,
          val.TrxRef,
          val.TransactionAmount,
          val.TransactionStatus,
          val.GatewayResponse,
          val.Channel,
          val.DateCreated,
          val,
        ];
        paymentDataTable.push(row);
        // setRefcode(val.TrxRef);
        return;
      });
      setPaymentDataTable(paymentDataTable);

      setLoader(false);
    };
    paymentDataTableResponse.length !== 0 && createPaymentTableData();
  }, [paymentDataTableResponse]);

  const handleVerifyPayment = async (reference) => {
    setLoader(true);
    try {
      await axios
        .get(
          `${baseURL}api/Applicant/VerifyPayment?Reference=${reference}`
        )
        .then(function (response) {
          setLoader(false);
          if (response.data.State === 1) {
            // toast.success(response.data.Msg)
            // console.log("yes")
          }
        })
        .catch(function (error) {
          if(error){
            toast.error(error.response.data.Msg)
            // console.log("no")
          }  
        
        })
        .then(function () {
          setLoader(false);
        });
    } catch (error) {
        
    }
  };

  return (
    <MuiThemeProvider theme={getMuiTheme}>
      <Box style={{ overflow: "hidden" }}>
        {loaderPage === true ? (
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress className={classes.circularprogress} />
          </Box>
        ) : (
          <>
            <Grid container spacing={5} style={{ overflow: "hidden" }}>
              <Grid Item xs={12}>
                <Paper className={classes.paper}>
                  <div style={{marginBottom:'20px'}}>
                  <Link to="/dashboard"  ><Button disableElevation startIcon={<ArrowBack/>}
                 variant="contained" style={{cursor:'pointer',textTransform:'initial', }}>
                  Go back
                  </Button></Link>
                  </div>
                  <div>
                    <div className={classes.divTitle}>
                      <span>
                        Paystack Transaction History
                      </span>
                    </div>
                    <LoadingOverlay
                      active={loader}
                      styles={{
                        overlay: (base) => ({
                          ...base,
                          background: "rgba(0, 0, 0, 0.15)",
                        }),
                      }}
                      spinner
                      text="Fetching Data for You..."
                    >
                      <MUIDataTable
                        title={
                          <img
                            src={newlogo}
                            alt="JM Tech Centre"
                            width="130px"
                            height="70px"
                          />
                        }
                        data={paymentDataTable}
                        columns={columns}
                        options={options}
                        style={{ transition: "0.7s" }}
                      />
                    </LoadingOverlay>
                  </div>
                  <ToastContainer/>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </MuiThemeProvider>
  );
};

export default withRouter(PaymentDetails);
