import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import { Link } from "react-router-dom";

export default function RegisterHere() {
  return (
    <>
      <br />
      <br />
      <Wrapper id="registerhere">
        <div className="lightBg">
          <div className="container">
            <div className="row textCenter"></div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div
                className="flex  radius8"
                style={{
                  gap: "3px",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "300px",
                  background: "#484848",
                 
                }}
              >
                <TextDiv style={{ flexWrap:'wrap', textAlign:'left',display:'flex', justifyContent:'space-around', gap:'3px', alignItems:'center'}}>
                  <h1
                    style={{ color: "#fff", marginBottom: "10px" }}
                    className="font25 bold"
                  >
                    Start a training today
                  </h1>
                  <p className="font15" style={{ color: "#fff" }}>
                    Want to be a part of our future tech ready champions? <br/> Explore available learning paths that meet your needs!
                  </p>
                  <br />
                  <ButtonsRow>
                    <div style={{ width: "190px" }}>
                      <Link to="home" style={{ textDecoration: "none" }}>
                        <FullButton title="Apply Here" />
                      </Link>
                    </div>
                  </ButtonsRow>
                </TextDiv>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const TextDiv = styled.div`
  width: 100%;
  text-align: center;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
