import React from "react";
import styled from "styled-components";
// Components

import FullButton from "../Buttons/FullButton";
// Assets
import Background from "../../assets/background.jpg"
import { Link } from "react-router-dom";

export default function Services() {

  return (
    <>
    <br/><br/>
     <Wrapper  id="services">
    <div className="lightBg">
      <div className="container">
        <div className="row textCenter">
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="flex  radius8" style={{ gap:'3px',alignItems:'center',justifyContent:'center', width:'100%', height:'300px', background:`linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7)),url(${Background})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundPosition:'center'}}>
           <TextDiv>
           <h1 style={{color:'#fff', marginBottom:'10px'}} className="font40 extraBold">Register Here to join a training</h1>
            <p className="font12"style={{color:'#fff'}}>
              Registration to get access to training on any of our courses is ongoing
              <br />
              Click on the Register button below to get started with your application
            </p><br/>
            <ButtonsRow
              >
                <div style={{ width: "190px" }}>
                <Link to="onboarding" style={{textDecoration:'none'}}>
                  <FullButton title="Get Started"/>
                  </Link>
                </div> 
              </ButtonsRow>
           </TextDiv>
            </div>
          </div>
         
        </div>
        
      </div>
  </Wrapper>
    </>

   
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const TextDiv = styled.div`
  // width: 100%;
  text-align: center;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
